import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{staticClass:"pr-0",attrs:{"cols":"12","md":"8"}},[_c(VFileInput,{attrs:{"name":"file","label":_vm.title,"rules":_vm.isRequired ? [_vm.fileInputModel ? true : '这是必选项'] : [!_vm.file || _vm.fileInputModel ? true : '已选择文件，请先上传或清空文件'],"accept":_vm.acceptType,"disabled":_vm.isDisabled,"outlined":"","dense":"","hide-details":"auto","clearable":"","value":_vm.fileInputModel},on:{"change":_vm.changeFile},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),(!_vm.isDisabled)?_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex"},[(!_vm.isShowProgressBar)?_c(VBtn,{staticClass:"me-2 mt-1",attrs:{"color":"success","small":"","disabled":!_vm.showVideoUpload},on:{"click":_vm.uploadFile}},[_vm._v(" 上传 ")]):_vm._e(),(_vm.isShowProgressBar && !_vm.isPaused)?_c(VBtn,{staticClass:"me-2 mt-1",attrs:{"outlined":"","small":""},on:{"click":_vm.uploadPause}},[_vm._v(" 暂停 ")]):_vm._e(),(_vm.isShowProgressBar && _vm.isPaused)?_c(VBtn,{staticClass:"me-2 mt-1",attrs:{"outlined":"","small":""},on:{"click":_vm.uploadResume}},[_vm._v(" 继续 ")]):_vm._e(),(_vm.isShowProgressBar)?_c(VBtn,{staticClass:"me-4 mt-1",attrs:{"small":"","color":"error"},on:{"click":_vm.uploadCancel}},[_vm._v(" 取消 ")]):_vm._e()],1)]):_vm._e()],1),(_vm.isShowProgressBar)?_c(VProgressLinear,{staticClass:"mt-2",attrs:{"value":_vm.progress.value,"height":"20","striped":""}},[_vm._v(" "+_vm._s(Math.ceil(_vm.progress.value))+"% ")]):_vm._e(),(!!_vm.fileInputModel)?_c('div',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mt-2 mx-2",attrs:{"text-color":"primary","color":"primary","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.uploadFileDelete()}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.fileName)+" ")])]}}],null,false,3208051660)},[_c('div',[_vm._v("点击删除该上传文件")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }